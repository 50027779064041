<template>
	<v-app :style="{background: $vuetify.theme.themes[isDark].background}">
		<!--Left menu-->
		<div>
			<v-navigation-drawer v-model="drawer" app :clipped="$vuetify.breakpoint.mdAndUp"
			                     :mini-variant="$vuetify.breakpoint.mdAndUp && !drawer"
			                     :permanent="$vuetify.breakpoint.mdAndUp" :temporary="$vuetify.breakpoint.smAndDown">

				<!--                           :permanent="$vuetify.breakpoint.mdAndUp" :temporary="$vuetify.breakpoint.smAndDown">-->
				<v-list dense>
					<!--				<v-list-item @click.stop="$vuetify.breakpoint.mdAndUp ? mini = !mini : drawer = !drawer">-->
					<!--					<v-list-item-icon>-->
					<!--            <v-icon size="30">{{-->
					<!--                !mini ? 'mdi-chevron-left' : 'mdi-chevron-right'-->
					<!--              }}-->
					<!--            </v-icon>-->
					<!--					</v-list-item-icon>-->

					<!--					<v-list-item-content>-->
					<!--&lt;!&ndash;&lt;!&ndash;						<v-list-item-title>{{  userTxt }}</v-list-item-title>&ndash;&gt;&ndash;&gt;-->
					<!--					</v-list-item-content>-->

					<!--&lt;!&ndash;					<v-btn icon>&ndash;&gt;-->
					<!--&lt;!&ndash;						<v-icon>mdi-chevron-left</v-icon>&ndash;&gt;-->
					<!--&lt;!&ndash;					</v-btn>&ndash;&gt;-->
					<!--				</v-list-item>-->
					<!--				<v-divider></v-divider>-->
					<div v-for="item in drawerItems" :key="item.title">
						<template v-if="item.divider">
							<v-divider></v-divider>
						</template>
						<template v-else-if="item.subItems">

							<v-list-group
								:prepend-icon="item.icon"
								no-action
								@click="$router.push(item.route)"
							>
								<template v-slot:activator>
									<v-list-item-content>
										<v-list-item-title v-text="item.title"></v-list-item-title>
									</v-list-item-content>
								</template>

								<v-list-item
									v-for="subItem in item.subItems"
									:key="subItem.title"
									:to="subItem.to"
									link
								>
									<v-list-item-icon>
										<v-icon>{{ subItem.icon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{ subItem.title }}</v-list-item-title>

								</v-list-item>
							</v-list-group>
						</template>
						<v-list-item link v-else :to="item.to">
							<v-list-item-icon>
								<v-icon>{{ item.icon }}</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title>{{ item.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<v-divider></v-divider>
				</v-list>
				<template v-slot:append>
					<v-divider></v-divider>

					<!--Logout-->
					<v-list-item @click="logout" dense>
						<v-list-item-icon>
							<v-icon>mdi-power</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ $t('views.layout.logout') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-divider></v-divider>
					<!--Dark mode-->
					<dark-mode-dialog ref="darkModeDialog"></dark-mode-dialog>
					<v-list-item dense link @click="$refs.darkModeDialog.openDialog()">
						<v-list-item-icon>
							<v-icon>
								mdi-invert-colors
							</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								{{
									$vuetify.theme.dark ? $t('views.layout.night_mode') : $t('views.layout.light_mode')
								}}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider></v-divider>
					<!--Switch language-->
					<v-list-item dense v-if="env === 'local'">
						<LocaleChanger/>
					</v-list-item>
				</template>
			</v-navigation-drawer>
		</div>

		<!--Top bar-->
		<v-app-bar app clipped-left clipped-right elevation="2">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title class="pl-1">
				<img v-if="$vuetify.theme.dark" :src="require('../../assets/images/logo/liz_white.svg')" alt="logo"
				     :width="$vuetify.breakpoint.smAndDown ? '30px' : '60px'">
				<img v-else :src="require('../../assets/images/logo/liz.svg')" alt="logo"
				     :width="$vuetify.breakpoint.smAndDown ? '30px' : '60px'">
			</v-toolbar-title>

			<div class="flex-grow-1"></div>
			<!--Global filters for dashboards & ratings on desktop-->
			<v-tooltip left
			           v-if="Helpers.isRouteWithGlobalFilters($route.name) && $vuetify.breakpoint.mdAndUp">
				<template v-slot:activator="{ on, attrs }">
					<div v-bind="attrs" v-on="on">
						<user-global-filters></user-global-filters>
					</div>
				</template>
				<span>{{ $t('views.global.filters.tooltip') }}</span>
			</v-tooltip>

			<v-btn v-if="$vuetify.breakpoint.smAndDown && ['ratings'].indexOf($route.name) > -1" icon
			       @click.stop="openRatingFilters()">
				<v-icon>mdi-filter-variant</v-icon>
			</v-btn>

			<!--Global filters for dashboards on mobile-->
			<v-btn
				v-if="$vuetify.breakpoint.smAndDown && ['dashboards', 'dashboard-unit-group'].indexOf($route.name) > -1"
				icon @click.stop="$refs.user_global_filters.openDialog()">
				<v-icon>mdi-filter-variant</v-icon>
			</v-btn>
			<user-global-filters ref="user_global_filters" noDisplay
			                     v-if="['dashboards', 'dashboard-unit-group'].indexOf($route.name) > -1 && $vuetify.breakpoint.mdAndDown">
			</user-global-filters>
		</v-app-bar>

		<!--Content-->
		<v-main>
			<router-view/>
			<v-snackbar
				v-model="snackbar"
				:timeout="timeout"
				:color="color"
			>
				<div v-html="text"></div>

				<template v-slot:action="{ attrs }">
					<v-btn
						color="blue"
						icon
						v-bind="attrs"
						@click="snackbar = false"
					>
						X
					</v-btn>
				</template>
			</v-snackbar>
		</v-main>

		<!--Footer-->
		<!--        <v-footer app padless>-->
		<!--            <v-row justify="center">-->
		<!--                <span>&copy; 2010 - {{ this.$moment().year() }}</span>-->
		<!--                <LocaleChanger/>-->
		<!--            </v-row>-->
		<!--        </v-footer>-->
<!--		<v-snackbar bottom :value="updateExists" color="green" timeout="-1">-->
<!--			{{ "Une mise à jour de l'application est disponible." }}-->


<!--			<template v-slot:action="{ attrs }">-->
<!--				<v-btn-->
<!--					text-->
<!--					v-bind="attrs"-->
<!--					@click="refreshApp"-->
<!--				>-->
<!--					Obtenir-->
<!--				</v-btn>-->
<!--			</template>-->
<!--		</v-snackbar>-->
	</v-app>
</template>

<script>
import {EventBus} from '@/services/Request'
import AuthLogic from "../../logics/AuthLogic";
import LocaleChanger from "../../components/communs/LocaleChanger";
import UserGlobalFilters from '../../components/communs/GlobalFilters';
import Helpers from '@/services/Helpers';
import DarkModeDialog from "@/components/communs/DarkModeDialog";
import ServiceWorkerUpdate from '@/mixins/ServiceWorkerUpdate'

export default {
	name: 'GuardedLayout',

	components: {
		DarkModeDialog,
		LocaleChanger,
		UserGlobalFilters
	},
	mixins: [ServiceWorkerUpdate],

	data: () => ({
		userTxt: '',
		languages: [],
		availableLanguages: [],
		drawer: null,
		mini: false,

		snackbar: false,
		text: '',
		timeout: 2000,
		env: process.env.VUE_APP_ENV,
		Helpers,

		darkModeDialog: false,
		color: 'primary',
	}),

	mounted() {
		this.languages = process.env.VUE_APP_I18N_AVAILABLE_LOCALE.split(",");
		this.availableLanguages = this.languages.filter(l => l !== this.$i18n.locale);
		const user = AuthLogic.user()
		this.userTxt = user.first_name + ' ' + user.last_name.charAt(0) + "."

		if (AuthLogic.user().has_dark_mode < 2) {
			this.$vuetify.theme.dark = AuthLogic.user().has_dark_mode > 0;
		} else {
			const mq = window.matchMedia('(prefers-color-scheme: dark)')
			this.$vuetify.theme.dark = mq.matches;
		}
	},

	created() {
		/**
		 * Main snackbar
		 */
		EventBus.$on('show-snackbar', (data) => {
			this.snackbar = false; //reset todo need stacked snack
			this.text = data.text || 'No information';
			this.timeout = data.timeout || 2000;
			this.color = data.color || 'green';
			this.snackbar = true;
		});

	    EventBus.$on('hide-snackbar', (data) => {
	      this.snackbar = false;
	    });
	},

	computed: {
		isDark() {
			return (this.$vuetify.theme.dark) ? 'dark' : 'light'
		},

		drawerItems() {
			let items = [];

			if (AuthLogic.hasRole('member') || AuthLogic.hasRole('quality')  || AuthLogic.hasRole('moderator') || AuthLogic.hasRole('reader')) {
				items.push(
					{
						icon: 'mdi-home',
						title: this.$t('views.layout.home'),
						to: {
							name: 'dashboard'
						},
					},
					{
						icon: 'mdi-poll',
						title: this.$t('views.layout.dashboards'),
						to: {
							name: 'dashboards'
						},
					},
					{
						icon: 'mdi-star-box',
						title: this.$t('views.layout.ratings'),
						to: {
							name: 'ratings'
						},
					},
				)
			}

			if (AuthLogic.hasRole('admin')) {
				items.push(
					{
						divider: true
					},
					{
						icon: 'mdi-account-box-multiple',
						title: this.$t('views.layout.users'),
						to: {
							name: 'users'
						},
					},
					{
						icon: 'mdi-map-marker-multiple',
						title: this.$t('views.layout.units_groups'),
						to: {
							name: 'units-groups'
						},
					},
					{
						divider: true
					},
					{
						icon: 'mdi-label-multiple',
						title: this.$t('views.layout.categories_groups'),
						to: {
							name: 'categories-groups'
						},
					},
					{
						icon: 'mdi-select-multiple-marker',
						title: this.$t('views.layout.unit_primary_categories'),
						to: {
							name: 'unit-primary-categories'
						},
					},
					{
						icon: 'mdi-file-hidden',
						title: this.$t('views.layout.reports'),
						to: {
							name: 'reports'
						},
					},
					{
					  icon: 'mdi-tablet-cellphone',
					  title: this.$t('views.layout.applications'),
					  route: {name: 'apps'},
						subItems: [
							{
								icon: 'mdi-apps',
								title: 'Apps',
								to: {
									name: 'apps'
								},
							},
							{
								icon: 'mdi-frequently-asked-questions',
								title: 'Questions',
								to: {
									name: 'questions'
								},
							},
							{
								icon: 'mdi-cellphone-link',
								title: 'Devices',
								to: {
									name: 'devices'
								},
							},
							{
								icon: 'mdi-tag-outline',
								title: 'Topics',
								to: {
									name: 'topics'
								},
							},
						]
					},
					{
						divider: true
					},
				)

				if (this.env !== 'preproduction') {
					items.push(
						{
							icon: 'mdi-cog',
							title: this.$t('views.layout.settings'),
							to: {
								name: 'settings-preprod-db-sync'
							},
						}
					)
				} else {
					items.push(
						{
							icon: 'mdi-cog',
							title: this.$t('views.layout.settings'),
							to: {
								name: 'settings-google-my-business'
							},
						}
					)
				}
			}

			return items;
		}
	},

	methods: {
		async logout() {
			let logoutResult = await AuthLogic
				.logout();

            if(logoutResult.redirect) {
                window.location.href = logoutResult.redirect;
                return;
            }

			await this.$router.push({name: 'home'})
		},

		openRatingFilters() {
			EventBus.$emit('open-rating-filters-drawer')
		},
	}
};
</script>
<style>
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
	padding-left: 40px;
}
</style>